import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    getAllWarehouse() {
        return this.http
            .get(`${this.api}/warehouse/`)
            .pipe(catchError(this.handleError<any[]>('getAll')));
    }
}
