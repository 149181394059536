import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert/alert.service';
import { Observable } from 'rxjs';
import { Pagination } from '@app/shared/models/pagination';
import { catchError, map } from 'rxjs/operators';
import { OrderConference } from '@app/shared/models/order-conference';

@Injectable({
  providedIn: 'root'
})
export class ErroService extends BaseService {
  constructor(public http: HttpClient, alertService: AlertService) {
    super(alertService, http);
  }

  getErrors(stage): Observable<any> {
    return this.http
      .get<any>(`${this.api}/errors/${stage}`)
      .pipe(catchError(this.handleError<any>('get')));
  }

}
