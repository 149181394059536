import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SacService extends BaseService {

  constructor(public http: HttpClient, alertService: AlertService) {
    super(alertService, http);
  }

  getAll(queryParams?: {
    [key: string]: any;
  }): Observable<any[]> {
    const defaultParams = {};

    queryParams = {
      ...defaultParams,
      ...queryParams
    };

    const params = new HttpParams({ fromObject: queryParams });
    return this.http
      .get<any[]>(`${this.api}/sac/`, {
        params
      })
      .pipe(catchError(this.handleError<any[]>('getAll')));
  }

  createSac(order: any): Observable<any> {
    return this.http
      .post<any>(`${this.api}/sac/`, order)
      .pipe(catchError(this.handleError<any>('create')));
  }

  resolveSac(sac: any): Observable<any> {
    return this.http
      .post<any>(`${this.api}/sac/resolve`, sac)
      .pipe(catchError(this.handleError<any>('resolve')));
  }
}
