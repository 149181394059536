import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { TransportCompany } from '@app/shared/models/transport-company';
import { AlertService } from '../alert/alert.service';

@Injectable({
    providedIn: 'root'
})
export class TransportCompanyService extends BaseService {

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    getAll(queryParams?: {
        [key: string]: any;
    }): Observable<TransportCompany[]> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<TransportCompany[]>(`${this.api}/transport`, {
                params
            })
            .pipe(catchError(this.handleError<TransportCompany[]>('getAll')));
    }

    get(
        id: any,
        queryParams?: {
            [key: string]: any;
        }
    ): Observable<TransportCompany> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<any>(`${this.api}/transport/${id}`, { params })
            .pipe(catchError(this.handleError<any>('get')));
    }

    create(transportCompanys: TransportCompany): Observable<TransportCompany> {
        return this.http
            .post<TransportCompany>(`${this.api}/transport`, transportCompanys)
            .pipe(catchError(this.handleError<TransportCompany>('create')));
    }

    edit(transportCompanys: TransportCompany): Observable<TransportCompany> {
        return this.http
            .patch<TransportCompany>(
                `${this.api}/transport/${transportCompanys.id}`,
                transportCompanys
            )
            .pipe(catchError(this.handleError<TransportCompany>('edit')));
    }

    delete(id): Observable<TransportCompany> {
        return this.http
            .delete<TransportCompany>(`${this.api}/transport/${id}`)
            .pipe(catchError(this.handleError<TransportCompany>('delete')));
    }
}
